// Scroll to top on any page navigation.
exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  const qp = new URLSearchParams(location.search);
  const showExpanded = qp.get('expanded');

  // If we are navigating from an episode page to a show page via the "see all episodes"
  // button, we want to scroll down to that position. For anything else, we should go back
  // to the top.
  if (showExpanded) {
    // eslint-disable-next-line no-undef
    const iframe = document.querySelector('iframe');

    if (iframe) {
      const yPos = iframe.getBoundingClientRect().y;
      // eslint-disable-next-line no-undef
      window.scrollTo(0, yPos);
    }
  } else {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }

  return false;
};
